@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    background-color: black;
    background-image: url(images/desktop_background.png);
    background-size: 1100px;
    background-position: top;
    background-position-x: center;
  }

  @media (min-width: 768px) {
    body {
      background-size: 1700px;
    }
  }

  img {
    border: 4px solid #74ffd4;
  }
}

@layer components {
  .link {
    box-shadow: 2px -3px #ff6ff9;
  }

  .text_shadow {
    text-shadow: 3px 2px #3b68ff;
  }
}

/* test */
